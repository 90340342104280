import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const target = document.getElementById('about-me');

const animate = (el, direction) => {
  let x = 150;
  let y = 150;
  if (direction === -1) {
    x *= -1;
    y *= -1;
  }
  gsap.fromTo(
    el,
    { x: x, y: y, autoAlpha: 0 },
    {
      duration: 1,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: 'power3',
      overwrite: 'auto',
    }
  );
};

const hide = (el) => {
  gsap.set(el, { autoAlpha: 0 });
};

// ScrollTrigger.create({
//   trigger: target,
//   onEnter: () => {
//     animate(target);
//   },
//   onEnterBack: () => {
//     animate(target, -1);
//   },
//   onLeave: () => {
//     hide(target);
//   },
// });

const aboutTimeline = gsap.timeline({
  scrollTrigger: {
    trigger: target,
    onEnter: () => {
      animate(target);
    },
    onEnterBack: () => {
      animate(target, -1);
    },
    onLeave: () => {
      hide(target);
    },
  },
});

// const aboutTimeline = gsap.timeline();

aboutTimeline.from('.quote', {
  opacity: 0,
  duration: 2.5,
  ease: 'slow',
});
aboutTimeline.from('#about2', {
  x: -25,
  y: 10,
  opacity: 0,
  ease: 'expo',
  duration: 1.25,
});
aboutTimeline.from('#about3', { autoAlpha: 0, duration: 1.25, ease: 'expo' });

aboutTimeline.from('#about4', { autoAlpha: 0, duration: 1.25, ease: 'expo' });
